<template>
  <div class="pd10 print-pane" ref="table">
    <div class="print-header">
      <h3 class="center">{{tempInfo.companyName}}</h3>
      <p class="center">
        <span>{{tempInfo.testingTime}}</span>
        <span v-if="printConfig.printExternal">气温：{{tempInfo.externalTemp}}℃</span>
        <span v-if="printConfig.printExternal">气湿：{{tempInfo.externalDampness}}%</span>
      </p>
    </div>
    <el-table :data="tempInfo.leftData" v-loading="loading" style="width: 100%">
      <el-table-column align="center" header-align="center" prop="houseNo" label="仓号"></el-table-column>
      <el-table-column align="center" header-align="center" prop="cangType" label="仓型"></el-table-column>
      <el-table-column align="center" header-align="center" prop="liangVarieties" label="品种"></el-table-column>
      <el-table-column align="center" header-align="center" prop="storekeeper" label="保管员"></el-table-column>
      <el-table-column align="center" header-align="center" prop="cangTemp" label="仓温"></el-table-column>
      <el-table-column align="center" header-align="center" prop="cangDampness" label="仓湿"></el-table-column>
      <el-table-column align="center" header-align="center" v-for="(highs,index) in tempInfo.highs" :key="index"
        :label="highs">
        <template v-for="item in tempCount">
          <el-table-column prop="temp" :label="tempInfo.headers[index * tempCount + item-1]" align="center"
            header-align="center">
            <template slot-scope="scope">
              {{index|tempFilter(item-1,tempInfo.dataList,scope)}}
            </template>
          </el-table-column>
        </template>
      </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import printJS from 'print-js'
  export default {
    filters: {
      tempFilter(highIndex, index, temps, scope) {
        return temps[scope.$index][highIndex * 3 + index]
      }
    },
    data() {
      return {
        tempInfo: {
          highs: [],
          leftData: []
        },
        tempCount: 0,
        time: '',
        loading: false,
         printConfig: {}
      }
    },
    mounted() {
      this.time = this.$route.query.time
      this.cfParamSee()
    },
    methods: {
      //获取打印数据
      cfParamSee() {
        this.$axios.Get(this.$api.cfParamSee, {}).then(res => {
          this.printConfig = res.data
          this.getPrintData()
        }).catch(() => {
          this.getPrintData()
        })
      },
      //获取打印数据
      getPrintData() {
        this.loading = true;
        this.$axios.Get(this.$api.summanyData, {
          "time": this.time
        }).then(res => {
          this.loading = false;
          this.tempInfo = res.data
          this.tempCount = res.data.headers.length / res.data.highs.length
          this.$nextTick().then(() => {
            setTimeout(() => {
              this.printHTML(this.$refs.table)
            }, 500)
          })
        }).catch(() => {
          this.loading = false;
        })
      },
      //获取元素
      elHtml(el) {
        const div = document.createElement('div')
        const els = el.querySelectorAll(".el-table")
        let printHeader = el.querySelector(".print-header")
        div.appendChild(printHeader.cloneNode(printHeader))
        els.forEach(item => {
          let item_ = item.cloneNode(item)
          let tr = item_.querySelectorAll(".el-table__header-wrapper tr");
          let tbody = item_.querySelector(".el-table__body-wrapper tbody");
          if (tr) {
            for(let i =tr.length- 1; i >= 0; i--){
              tbody.insertBefore(tr[i].cloneNode(tr[i]), tbody.querySelectorAll("tr")[0])
              tr[i].remove()
            }

          }
          div.appendChild(item_)
        })
        return div
      },
      //打印
      printHTML(el) {
        const div = document.createElement('div')
        const printDOMID = 'printDOMElement'
        div.id = printDOMID

        div.appendChild(this.elHtml(el))

        // 将新的 DIV 添加到页面 打印后再删掉
        document.querySelector('body').appendChild(div)

        printJS({
          documentTitle: "汇总打印",
          printable: printDOMID,
          type: 'html',
          scanStyles: false,
          style: ' table {width: 100%!important;} .table th > .cell { padding-left:0!important; padding-right:0!important;} .table .cell { padding-left:0!important; padding-right:0!important; } table tr td,th { border-collapse: collapse;padding: 15px;border:1px #EBEEF5 solid;text-align:center; } .center{text-align:center;} '
        })
        div.remove()
      }

    }
  }
</script>

<style scoped lang="less">
  // .A4 {
  //   page-break-before: auto;
  //   page-break-after: always;
  // }

  // @page {
  // size: A4 portrait;
  /*portrait： 纵向打印,  landscape: 横向*/
  // }

  .print-header {

    p {
      margin-top: 5px;
      margin-bottom: 10px;
    }

    span {
      font-size: 12px;
    }

    span+span {
      margin-left: 15px;
    }
  }
</style>
